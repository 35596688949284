.left-container {
  height: 100vh;
  background: radial-gradient(50% 50% at 50% 50%, #70DDFB 0%, #5AB9F9 100%);
}

.right-container {
  background: #FFFFFF
}

.left-content-1,
.left-content-2 {
  // text-align: center;
  color: #FFFFFF;
}

.left-content-1 {
  font-size: 24px;
  line-height: 28px;
}

.left-content-2 {
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 35px;
}

.left-content-mob-image {
  display: none;
}

@media (max-width: 600px) {
  .left-content-dek-image {
    display: none;
  }
  .left-content-mob-image {
    display: block;
    width: 100%;
  }
  .left-container {
    height: fit-content;
  }
  .right-container {
    position: fixed;
    bottom: 0;
    background-color: transparent;
  }
  .left-container {
    padding-top: 60px;
  }
}
