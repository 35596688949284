.invite-form{
    height: Calc(100vh - 64px);
    background-color: #F5FBFF;
}

.list-container{
    height: Calc(100vh - 64px);
}

.inviteform-disabled {
    width: 100%;
    height: 100%;
    background-color: #F5FBFF;
    z-index: 0.5;
}
@media (max-width:900px) {
    .list-container{
        margin-bottom: 10px;
        height: fit-content;
      }
    .invite-form{
        height: fit-content;
    }
}
@media (max-width:768px) {
   
    .list-container{
        margin-bottom: 10px;
        height: 100vh;
        align-items: flex-start;
      }
    .invite-form{
        display: none;
    }
}