@import '../../../assets/scss/variables';

.w-80 {
  width: 80%;
}

.w-20 {
  width: 20%;
}

.row {
  display: flex;
}

.text-right {
  text-align: right;
}

.sub-text {

  font-weight: 400;
  font-size: 12px;
  color: #727272;
  margin: 0px 0px 40px 0px
}

.userDetailCard {
  background: #FFFFFF;
  box-shadow: 0px 10px 25px rgba(120, 144, 156, 0.25);
  border-radius: 24px;
  padding: 40px 24px 20px 24px;
}

.agree-button {
  padding: 8px 68px;
  height: 40px;
  background: #F3F3F3;
  border-radius: 8px;
}

.text-center {
  text-align: center;
}

.heading {

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  color: #333333;
}

.footer-text {

  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin-top: 44px;
}