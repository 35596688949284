.sidebar {
  .MuiPaper-root {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1.6px 3.6px rgba(47, 60, 69, 0.13), 0px 0.3px 0.9px rgba(47, 60, 69, 0.1);
border-radius: 0px;
  }
}
.helloIcon{
  margin-top: 8px;
  margin-left: 5px;
}

@media (max-width:768px) {
  .sidebar {
    .MuiPaper-root {
      display: flex;
      justify-content: space-between;
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }
}
