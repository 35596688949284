@media screen and (max-width: 768px) {
 .page-request-details, .page-view-request {
   .page-heading {
     font-size: 1.1rem;
   }
 }
}

.page-request-details, .page-view-request {
  padding: 0;

  .page-top-section {
    padding: 0;
    margin: 0;
    width: 100%;
    @media screen and (max-width: 768px) {
      margin-top: 1rem;

    }
    > .MuiGrid-item {
      padding: 0;
      margin: 0;
      width: 100%;
      > div {
        margin: 0;
        width: 100%;
        padding: 0;
      }
      .page-top-section__items {
        padding: 1rem;
        background: linear-gradient(180deg, #dcf1ff, transparent);
        grid-gap: 1rem;
        border-bottom: 1px solid #ccdef0;
        border-top: 1px solid #ccdef0;
        > div {
          padding: 0;
          > div {
            border: 1px solid #b2c4ff;
            background: linear-gradient(358deg, #ebf7ff, #fff);
            border-radius: 4px;
            box-shadow: 0 0 10px #e2eef9;
            min-height: 95px;
            min-width: 220px;
          }

        }
      }
    }
  }
  .top-section__buttons {
    grid-gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: end;
    border-bottom: 1px solid #ccdef0;
    border-top: 1px solid #ccdef0;

    background: linear-gradient(180deg, #dcf1ff, transparent);

    padding-right: 2rem !important;

    @media screen and (max-width: 768px) {
      padding: 0.5rem 16px !important;
      border-radius: 0;
      background: #fff;
      button {
        width: fit-content;
      }
    }
    button {
      min-height: 3rem;
      text-wrap: nowrap;
    }
  }
  .tab-main-con {
    border-bottom: 1px solid #ddd;
    margin-bottom: 2rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }
    button {
      padding: 0.75rem 2rem;
      @media screen and (max-width: 768px) {
        padding: 0.75rem 1rem;
      }
      border-right: 1px solid #ddd;
      color: #8f8f8f;
      &.Mui-selected{
        background: linear-gradient(359deg, #ebf7ff, transparent);
        color: #245373;
        box-shadow: 0px -2px 0px #5ab9f9 inset;
      }
    }
  }
}

.circle-photo {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  object-fit: cover;
}

.page-agencies{
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    padding: 0
  }
}
