@import '../../../../assets/scss/variables';

.w-100 {
  width: 100%;
}

.row {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.heading {
  font-weight: 500;
  font-size: 18px;
  color: #333333;
}

.grid-margin-bottom {
  margin-bottom: -8rem;
}

.margin-top-button {
  margin-top: -5rem;
}

.sub-text {
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.text-right {
  text-align: right;
}

.input-style {
  padding: 16px 10px;
  background: #F6F7FB;
  border-radius: 5px;
  width: 100%;
  border: 0 !important;
}

.confirm-button {
  padding: 8px 68px;
  height: 40px;
  background: #F3F3F3;
  border-radius: 8px;
}

.text-center {
  text-align: center !important;
}

.cards {
  transition: 0.5s ease-in-out;
  border-radius: 12px !important;
}

.cards:hover {
  box-shadow: 0 10px 25px rgba(120, 144, 156, 0.2);
  border-radius: 12px;
}