.defaultButton {
  text-transform: capitalize;
  font-weight: 600;
  min-height: 45px;
  font-size: 1rem;
}

.defaultButton:hover {
  background-color: #5AB9F9;
  color: white;
}

.textButton {
  text-transform: capitalize;
  font-weight: 600;
}

@media (max-width: 768px) {
  .invite-btn {
    margin-top: 0 !important;
  }
}