.MuiAppBar-positionStatic {
  box-shadow: none;
}

@media (max-width: 900px) {
  .images {
    box-shadow: 0 10px 25px rgba(120, 144, 156, 0.25);
    border-radius: 5px;
  }
}
