@import "../../../../assets/scss/variables";

.document-repository-tabs {
  &.Mui-selected {
    color: #5ab9f9;
    border-bottom: 3px solid #5ab9f9;
  }

  outline: 0;
  border: 0;
  margin: 12px 18px;
  cursor: pointer;
  font-weight: 500;
  padding: 12px 3px;
  color: #727272;
  font-size: 16px;
}

.details-box {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.15px;
  color: #212121;
}

.details-box-heading {
  font-weight: 400;
  font-size: 14px;
  color: #727272;
}

.body-head {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #263238;
}

.additional-body-head {
  font-weight: 400;
  font-size: 14px;
  color: #727272;
}

.additional-document-card {
  font-weight: 600;
  font-size: 14px;
  color: #263238;
}

.additional-document-card {
  padding: 10px 0 !important;
}

.document-card {
  border-radius: 17.5px;
  padding: 0;
  background-color: white;
}

.card-right {
  background: #f5fbff;
  padding: 50px 0 70px 0;
}

.card-head {
  background: white;
  margin-bottom: 40px;
  border-radius: 17.5px 17.5px 0 0;
}

.card-header-text {
  font-weight: 500;
  font-size: 16px;
  color: #212121;
  padding: 22px 0 0 0;
}

.card-heading-right {
  font-weight: 500;
  font-size: 16px;
  color: #212121;
}

.border-row {
  border: 1px solid #dadada;
  border-radius: 8px;
}

// dropdown
.dropdown>.MuiSelect-select {
  padding: 10px;
  background-color: white;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
  border: 1px solid #dadada !important;
  border-radius: 5px;
}

.action-icon {
  padding: 12px;
  border-radius: 8px;
  color: #333333;
}

.upload-button {
  color: white;
  padding: 8px 20px;
  background: #5ab9f9;
  box-shadow: 0 10px 25px rgba(90, 185, 249, 0.25);
  border-radius: 6px;
}

.instructions {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #727272;
  margin-top: 20px !important;
}

.dotted-card {
  background: #ffffff;
  border: 1px dashed #5ab9f9;
  border-radius: 12px;
  padding: 10px 10px 10px 10px;
}

.empty-data-card {
  background: #f5fbff;
  border: 1px dashed #5ab9f9;
  border-radius: 12px;
  text-align: center;
  padding: 40px 30px;
}

.dropdown .MuiSelect-select {
  width: 100%;
  height: 18px;
}

.mob-card {
  padding: 20px 0 20px 0;

  display: none;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  border: 1px solid #5ab9f9;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .tabs-1 .MuiBox-root {
    padding: 10px !important;
  }

  .dropdown .MuiSelect-select {
    width: 100%;
    height: 18px;
  }

  .card-right {
    display: none !important;
  }

  .mob-card {
    padding: 20px 0 20px 0;

    display: block;
  }

  .mob-card .content-card {
    box-shadow: 0px 10px 25px rgba(120, 144, 156, 0.2);
  }
}