@import "./assets/scss/core";

@import "~bootstrap/scss/mixins/banner";

@include bsBanner("");

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

@import '~formiojs/dist/formio.full.min.css';

@import '~flag-icon-css/css/flag-icons.min.css';

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.main-body {
  height: calc(100vh - 70px);
}

.shield-checkbox {
  fill: white !important;
}

@media (min-width: 1024px) {
  .pageWrapper {
    & > .paperWrapper {
      height: 100%;
    }

    height: calc(100vh - 70px);
  }
}

.referee-form {
  .form-control {
    &.dropdown {
      display: block;
    }
  }

  .formio-component-submit {
    .btn {
      background: #5bb9f9;
      border: #5bb9f9;
      color: #ffffff;
      padding: 2px 80px;
      text-align: right;
      line-height: 1.75;
      font-size: 1rem;
      font-weight: 600;
      min-height: 45px;
      margin-top: 15px;
    }

    text-align: right;
  }
}

@media (max-width:768px) {
  .referee-form {
    .form-control {
      &.dropdown {
        display: block;
      }
    }
  
    .formio-component-submit {
      .btn {
        background: #5bb9f9;
        border: #5bb9f9;
        color: #ffffff;
        padding: 5px;
        text-align: center !important;
        line-height: 1.75;
        font-size: 1rem;
        font-weight: 600;
        min-height: 45px;
        margin-top: 15px;
        width:150px
      }
  
      text-align: right;
    }
  }
}

.view-referee-form {
  .formio-component-submit {
    display: none;
  }
}

.empty-image {
  max-height: 100px;
}

.btn {
  &.btn-primary {
    &:hover {
      background: #0069d9;
    }

    background: #5AB9F9;
    color: #ffffff;
    border-color: #5AB9F9;
  }
}

.MuiInputBase-input {
  .flag-icon {
    display: none;
  }
}

.formio-dialog {

  .card-header-tabs {
    .nav-link[href="#api"], .nav-link[href="#conditional"],
    .nav-link[href="#logic"], .nav-link[href="#layout"] {
      display: none;
    }
  }

  .formio-component-labelPosition, .formio-component-description, .formio-component-autofocus,
  .formio-component-tooltip, .formio-component-prefix, .formio-component-suffix,
  .formio-component-inputMask, .formio-component-validateOn, .formio-component-unique,
  .formio-component-allowMultipleMasks, .formio-component-customClass, .formio-component-displayMask,
  .formio-component-tabindex, .formio-component-autocomplete, .formio-component-showWordCount,
  .formio-component-showCharCount, .formio-component-mask, .formio-component-hidden,
  .formio-component-spellcheck, .formio-component-tableView, .formio-component-modalEdit,
  .formio-component-multiple, .formio-component-defaultValue, .formio-component-persistent,
  .formio-component-inputFormat, .formio-component-protected, .formio-component-dbIndex,
  .formio-component-case, .formio-component-truncateMultipleSpaces, .formio-component-encrypted,
  .formio-component-redrawOn, .formio-component-clearOnHide, .formio-component-customDefaultValuePanel,
  .formio-component-calculateValuePanel, .formio-component-calculateServer,
  .formio-component-allowCalculateOverride, .formio-component-editor, .formio-component-delimiter,
  .formio-component-decimalLimit, .formio-component-requireDecimal, .formio-component-custom-validation-js,
  .formio-component-json-validation-json, .formio-component-errors, .formio-component-errorLabel,
  .formio-component-labelWidth, .formio-component-labelMargin, .formio-component-shortcut,
  .formio-component-inputType, .formio-component-optionsLabelPosition, .formio-component-widget,
  .formio-component-uniqueOptions, .formio-component-dataSrc, .formio-component-dataType,
  .formio-component-idPath, .formio-component-template, .formio-component-refreshOn, .formio-component-refreshOnBlur,
  .formio-component-clearOnRefresh, .formio-component-searchEnabled, .formio-component-selectThreshold,
  .formio-component-readOnlyValue, .formio-component-customOptions, .formio-component-useExactSearch,
  .formio-component-kickbox, .formio-component-inputMaskPlaceholderChar, .formio-component-displayInTimezone,
  .formio-component-useLocaleSettings, .formio-component-shortcutButtons, .formio-component-defaultDate,
  .formio-component-enableMinDateInput, .formio-component-enableMaxDateInput, .formio-component-panel-disable-function,
  .form-group[class*="formio-component-widget.type"],
  .form-group[class*="formio-component-validate.onlyAvailableItems"],
  .form-group[class*="formio-component-validate.minWords"],
  .form-group[class*="formio-component-validate.maxWords"],
  .form-group[class*="formio-component-validate.pattern"],
  .form-group[class*="formio-component-validate.customMessage"] {
    display: none;
  }
}

.formio {
  &.builder {
    .formcomponent[data-key="password"], .formcomponent[data-key="checkmatrix"],
    .formcomponent[data-key="number"], .formcomponent[data-key="button"],
    .formcomponent[data-key="url"], .formcomponent[data-key="tags"],
    .formcomponent[data-key="address"], .formcomponent[data-key="day"],
    .formcomponent[data-key="time"], .formcomponent[data-key="currency"],
    .formcomponent[data-key="signature"], .formcomponent[data-key="content"],
    .formcomponent[data-key="columns"], .formcomponent[data-key="fieldset"],
    .formcomponent[data-key="panel"], .formcomponent[data-key="tabs"],
    .formcomponent[data-key="well"], .form-builder-panel[ref="group-panel-data"],
    .form-builder-panel[ref="group-panel-premium"] {
      display: none;
    }
  }
}
