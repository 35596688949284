.pop-up-dotted-card-dbs {
  position: relative;
  background: #FFFFFF;
  border: 1px dashed #5AB9F9;
  border-radius: 12px;
  text-align: center;
  padding: 30px ;
  box-shadow: 0 10px 25px rgba(120, 144, 156, 0.2);
}

.custom-card-dbs {
  background: #FFFFFF;
  border: 1px solid white;
  border-radius: 16px;
  padding: 16px 10px;
}

.uploaded-card-dbs {
  background: #FFFFFF;
  border: 1px solid white;
  border-radius: 6px;
  padding: 16px 20px;
}

.pop-up-dotted-card3 {
  position: relative;
  background: #FFFFFF;
  border: 1px dashed #5AB9F9;
  border-radius: 12px;
  padding: 30px; 
}

.start-button {
  background: #5AB9F9;
  box-shadow: 0 10px 25px rgba(90, 185, 249, 0.25);
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.action-btn-groups {
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #263238;
  padding: 8px 25px;
  margin: 6px auto 12px;
}

.bg {
  background: url("../../../assets/images/blurBg.svg") no-repeat;
  background-size: cover;
  min-height: 600px;
  border-radius: 30px;
}

@media (max-width: 1000px) {
  .tab-container .MuiTabs-scroller {
    overflow-x: scroll !important;
  }
}
