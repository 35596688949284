@import '../../../assets/scss/variables';

.logo-icon {
  margin-top: 25px;
}

.login {
  @media (max-width: 768px) {
    margin-top: 30px;
  }

  @media (max-width: 768px) {

    padding-bottom: 30px;
  }
}


.redirection-link {
  text-decoration: none;
  // border-bottom: 1px solid $primary;
  color: $primary;

  .MuiTypography-root {
    border-bottom: 2px solid $primary;
  }
}