.comment-heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #727272;
}

.optional-heading {
  font-weight: 400;
  font-size: 12px;
  color: #727272;
}

.text-area {
  width: 92%;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 2px;
  border: 0;
}
.card-head-reference {
  background: #E9F6FF;
  border-radius: 17px 17px 0 0;
  padding: 10px;
}
