@import "variables";

// Width and height

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

// Margin and Padding

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

a {
  cursor: pointer;
}

// Icons
.fonticon-wrap {
  width: 2%;
  float: left;
  margin: 0.25rem;

  i.fa {
    font-size: 1.35rem !important;
  }
}

.round {
  border-radius: 1.5rem;
}

.square {
  border-radius: 0;
}

img {
  &.img-border {
    border: 5px solid $white;
  }
}

ul {
  &.no-list-style {
    list-style: none;
  }
}

// Cursors

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-default {
  cursor: default;
}

.cursor-progress {
  cursor: progress;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-grab {
  cursor: grab;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
