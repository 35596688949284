.comment-heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #727272;
}

.optional-heading {
  font-weight: 400;
  font-size: 12px;
  color: #727272;
}

.text-area {
  width: 92%;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 2px;
  border: 0;
}
.approve-ref-btn:hover{
  background: lightblue;
  
}
.request-table-body-row{
  color: black;
}
.request-table-body-row:hover{
  background: #FFFFFF;
  border: 1px solid rgba(90, 185, 249, 0.25);
  /* Beautify2 */
  
  box-shadow: 0px 5px 25px rgba(120, 144, 156, 0.2);
  border-radius: 8px;
}
